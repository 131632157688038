import React, { FC } from "react";

const Spinner: FC = () => {
  return (
    <div>
      <div>
        <div />
        <div />
        <div />
        <div />
      </div>
      <div>
        <div />
        <div />
        <div />
        <div />
      </div>
      <div>
        <div />
        <div />
        <div />
        <div />
      </div>
      <div>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner;
