import styled from "styled-components";
import { sansSerif } from "../../typography";

export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.formElementPadding};
  margin-bottom: calc(${({ theme }) => theme.margin} / 2);
  background: transparent;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.textColor};

  ${sansSerif};
  color: ${({ theme }) => theme.textColor};
`;
