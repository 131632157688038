import { ApiResponse, ApiResult } from "../src/components/api/api.hook";

export const apiCallFixture = async <P, R>(
  success: boolean,
  time: number,
  params: P,
  result: R
): ApiResponse<R> => {
  // console.log(params);
  return new Promise<ApiResult<R>>((resolve, reject) => {
    setTimeout(() => {
      success ? resolve(result) : reject(result);
    }, time);
  });
};
