import styled, { css } from "styled-components";
import { Link } from "gatsby";

type buttonProps = {
  right?: boolean;
};

const buttonStyle = css<buttonProps>`
  display: inline-block;
  width: max-content;
  padding: ${({ theme }) => theme.formElementPadding};
  background: ${({ theme }) => theme.accentColor};

  font-family: ${({ theme }) => theme.font?.serif};
  text-decoration: none;
  color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.accentColor};
  transition: all 0.15s ease-in-out;

  ${({ right }) => right && ` float: right; clear: both; `}

  &:hover,
  &:focus {
    background: transparent;
    color: ${({ theme }) => theme.accentColor};
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const Button = styled.button<buttonProps>`
  ${buttonStyle};
`;

export const ButtonLink = styled(Link)<buttonProps>`
  ${buttonStyle as TemplateStringsArray};
`;
