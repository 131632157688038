import React, { FC } from "react";
import Layout from "../components/layout";
import { useSendContact } from "../components/contact/contact.hook";
import { H2, Paragraph } from "../components/ui/typography";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "../generated/graphql";
import { Button } from "../components/ui/form/button";
import { Header } from "../components/ui/semanticElements/header";
import { Main } from "../components/ui/semanticElements/main";
import { Input } from "../components/ui/form/input";
import { TextArea } from "../components/ui/form/textArea";

const ContactPage: FC = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allContentfulContactPage {
        nodes {
          seoPageTitle
          seoPageDescription
          title
          intro
        }
      }
    }
  `);
  const { seoPageTitle, seoPageDescription, title, intro } = data.allContentfulContactPage.nodes[0];

  const { sendContact, ApiProgress } = useSendContact();
  const handleSubmitContactForm = (): void => sendContact({ firstName: "manuTest" });

  return (
    <Layout pageTitle={seoPageTitle as string} pageDescription={seoPageDescription as string}>
      <Header>
        <H2 as="h1">{title}</H2>
        <Paragraph as="h2">{intro}</Paragraph>
      </Header>
      <Main horizontalCenter half>
        <form aria-label="contactForm">
          <Input type="text" placeholder="Dein Name" name="name" aria-label="name" />
          <TextArea placeholder="Deine Nachricht" name="message" aria-label="message" />
          <Input type="text" placeholder="Deine E-Mail" name="email" aria-label="email" />
          <Button right onClick={handleSubmitContactForm}>
            Nachricht absenden
          </Button>
        </form>
        <ApiProgress />
      </Main>
    </Layout>
  );
};

export default ContactPage;
