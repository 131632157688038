import React, { FC, useState } from "react";
import Error from "./error";
import Spinner from "../ui/spinner";

/**
 * This hook is used as a general purpose apiCall service.
 * The desired apiCall needs to be passed as a parameter to the hook,
 * hence I recommend wrapping this hookWith a more specific hook.
 * You can find an example in the contact.hook file
 *
 * exposed fields:
 *    performApiCall  :   function to trigger the defined apiCall,
 *    data            :   if apiCall succeeded contains returned data, else undefined,
 *    ApiProgress     :   Component representing the loading state for a given apiCall
 *
 * generic type P     :   type of parameters used for the given apicall,
 * generic type R     :   type of Result of a given apiCall
 */

export type ApiError = string[];

export type ApiResult<R> = R | ApiError;

export type ApiResponse<R> = Promise<ApiResult<R>>;

export type ApiCall<P, R> = (params: P) => ApiResponse<R>;

type ApiState<R> = { loading: boolean; data?: R; errors?: string[] };

export type ApiHook<P, R> = {
  data?: R;
  errors?: ApiError;
  performApiCall: (params: P) => void;
  ApiProgress: FC;
};

export function useApi<P, R>(apiCall: ApiCall<P, R>): ApiHook<P, R> {
  const [apiState, setApiState] = useState<ApiState<R>>({ loading: false });

  const ApiProgress: FC = () => {
    if (apiState.loading) {
      return <Spinner />;
    } else if (apiState.errors) {
      return <Error description={"error"} />;
    }
    return null;
  };

  const performApiCall = async (params: P): Promise<void> => {
    setApiState({ loading: true });
    try {
      const response = await apiCall(params);
      setApiState({ loading: false, data: response as R });
    } catch (errors) {
      setApiState({ loading: false, errors: errors });
    }
  };

  return {
    performApiCall,
    data: apiState.data,
    errors: apiState.errors,
    ApiProgress
  };
}
