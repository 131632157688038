import { FC } from "react";
import { useApi } from "../api/api.hook";
import { apiCallFixture } from "../../../fixtures/apiCall.fixtures";

export type ContactData = {
  firstName: string;
  // Todo to be extended;
};

export interface ContactHook {
  sendContact: (params: ContactData) => void;
  ApiProgress: FC;
}

export function useSendContact(): ContactHook {
  const { ApiProgress, performApiCall } = useApi<ContactData, void>(params =>
    apiCallFixture<ContactData, void>(true, 1000, params, undefined)
  );

  return {
    sendContact: performApiCall,
    ApiProgress
  };
}
